import React from 'react';
import useGoogleSheets from 'use-google-sheets';
import Table from 'react-bootstrap/Table';

const Beschikbaarheid = () => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
    sheetsOptions: [{ id: 'Weken', headerRowIndex: 1 }],
  });

  console.log('data', data )

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error!</div>;
  }

  const Passed = date => {
    const input = date.split("-")
    const read_date = new Date(parseInt(input[2],10),parseInt(input[1],10) - 1,parseInt(input[0],10))

    const thresholdDate = new Date();
    thresholdDate.setFullYear(thresholdDate.getFullYear() + 2);

    if (!isNaN(read_date.getTime()) && read_date > new Date() && read_date < thresholdDate) {
        return true
    }
    else {
      return false
    }
  } 

  const filter_year = () => { 
    const _IN = data[0]['data'].map(d => Passed(d['Begin']) ? d['Begin'] : null)
    // Step 1: Filter non-null values
    const validDates = _IN.filter(date => date !== null);

    // Step 2: Extract years
    const years = validDates.map(date => date.split('-')[2]);

    // Step 3: Deduplicate
    const uniqueYears = [...new Set(years)];

    // Step 4: Render years
    return uniqueYears.length > 1 ? uniqueYears.join('/') : uniqueYears[0];
  }

  return (
  <div id='beschikbaarheid' className='Beschikbaarheid-paragraaf'>
    <h2>Beschikbaarheid {filter_year()}</h2>
    <Table striped bordered hover responsive>
      <thead>
            <tr>
              <th key={0}>Week</th>
              <th key={1}>Datum</th>
              <th key={2}>Beschikbaarheid</th>
            </tr>
      </thead>
      <tbody>
      {data[0]['data'].map(d => 
          ( Passed(d['Begin']) ?
          <tr style={{ backgroundColor: d['Beschikbaarheid'] === '0' ? '#9FE2BF': '#DE3163'}}>
              <td>{d['Week']}</td>
              <td>{d['Begin'] + ' tot ' + d['Eind'] }</td>
              <td>{d['Beschikbaarheid'] === '0' ? 'Beschikbaar': 'Bezet'}</td>
          </tr>
          :
          <div></div>
          )
      )}
      </tbody>
    </Table>
  </div>);
};

export default Beschikbaarheid